import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { BlogArticleStandard } from "../../Shared"

export const LatestBlogArticles = ({ articles }) => (
  <section className="background-grey-lightest">
    <section className="wrapper-width wrapper-height latest-blog-articles">
      <div className="latest-blog-articles-header">
        <h2 className="heading-large">Our latest advice</h2>
        <Link to="/blog" className="latest-blog-articles-link">
          All articles
        </Link>
      </div>
      <div className="grid-col-3">
        {articles.map(({ node }) => (
          <BlogArticleStandard
            key={node.contentful_id}
            title={node.title}
            featuredText={node.featuredText.featuredText}
            featuredImage={node.featuredImage.fluid}
            featuredImageDescription={node.featuredImage.title}
            tags={node.tags}
            slug={`/blog/${node.slug}`}
          />
        ))}
      </div>
    </section>
  </section>
)

LatestBlogArticles.propTypes = {
  articles: PropTypes.array.isRequired,
}
