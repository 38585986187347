import React from "react"
import axios from "axios"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { useSnackbar } from "react-simple-snackbar"

import {
  BreakDownTable,
  ButtonWithLoaderPayment,
  SnackBarOptions,
} from "../../../Shared"
import {
  convertPriceToPounds,
  handleBackendErrors,
} from "../../../../utilities"
import { stripeOptions } from "../"
import { BoilerOrderContext } from "../../"

export const PayNow = ({
  boilerUpFrontCosts,
  userEmail,
  setOrderCompleted,
  orderId,
  updateOrder,
}) => {
  const order = React.useContext(BoilerOrderContext)
  const [openSnackbar] = useSnackbar(SnackBarOptions)
  const [isGettingPaymentIntent, setIsGettingPaymentIntent] = React.useState(
    false
  )
  const [clientSecret, setClientSecret] = React.useState("")
  const [disabled, setDisabled] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [processing, setProcessing] = React.useState(false)
  const [succeeded, setSucceeded] = React.useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const completeGoogleAnalyticsConversion = async () => {
    try {
      dataLayer.push({
        event: "purchase",
        transactionId: order.orderId,
        transactionAffiliation: "Home Assist Repairs and Servicing Ltd",
        transactionTotal: convertPriceToPounds(
          boilerUpFrontCosts.upfrontAmount
        ),
        transactionTax: 0,
        transactionShipping: 0,
        transactionProducts: [
          {
            sku: order.boilerId,
            name: order.boiler.name,
            category: "Boiler",
            price: order.boiler.price,
            quantity: 1,
          },
          {
            name: "Labour Cost",
            category: "Labour Cost",
            price: order.quote.labourCost,
            quantity: 1,
          },
          {
            sku: order.steps.controls.data.id,
            name: order.steps.controls.data.name,
            category: "Controls",
            price: order.steps.controls.data.price,
            quantity: 1,
          },
          {
            name: "Boiler Flue",
            category: "Boiler Flue",
            price: order.quote.flueCost,
            quantity: 1,
          },
        ],
      })
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    window.dataLayer = window.dataLayer || []
  }, [])

  React.useEffect(() => {
    setIsGettingPaymentIntent(true)

    const createPaymentIntent = async () => {
      try {
        const getPaymentIntent = await axios({
          method: "post",
          url: `${process.env.GATSBY_API_BASE_URL}/order/create-payment`,
          data: {
            orderId: orderId,
          },
        })
        setClientSecret(getPaymentIntent.data.clientSecret)
      } catch (error) {
        openSnackbar(handleBackendErrors(error, "create payment form"))
      }
      setIsGettingPaymentIntent(false)
    }

    createPaymentIntent()
  }, [])

  const handleChange = async (event) => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    setProcessing(true)
    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: userEmail,
      payment_method: {
        card: elements.getElement(CardElement),
      },
    })

    if (payload.error) {
      setError("Payment card failed, please use another card")
      setProcessing(false)
    } else {
      setError(null)
      setProcessing(false)
      setSucceeded(true)
      updateOrder((order) => ({
        ...order,
        steps: {
          ...order.steps,
          payment: {
            ...order.steps.payment,
            data: {
              ...boilerUpFrontCosts,
            },
          },
        },
      }))
      await completeGoogleAnalyticsConversion()
      setOrderCompleted(true)
    }
  }

  return (
    <form
      id="pay-now-form"
      onSubmit={handleSubmit}
      className={
        isGettingPaymentIntent ? "payment-form loading" : "payment-form"
      }
    >
      <p>Pay for the full cost of your boiler installation now.</p>
      <BreakDownTable
        rows={[
          {
            id: "1",
            label: "Total",
            price: convertPriceToPounds(boilerUpFrontCosts.upfrontAmount),
          },
        ]}
      />
      <CardElement
        id="card-element"
        options={stripeOptions}
        onChange={handleChange}
      />
      {error && <span className="form-error-text">{error}</span>}
      <ButtonWithLoaderPayment
        isLoading={processing}
        disabled={processing || disabled || succeeded}
        type="submit"
        id="submit"
      >
        <span className="icon-padlock"></span>
        <span className="payment-option-pay-button">Pay now</span>
      </ButtonWithLoaderPayment>
    </form>
  )
}
