import React from "react"
import { Link } from "gatsby"

import { Accreditations } from "../.."

export const FooterMinimal = () => (
  <div className="footer-minimal">
    <span className="copyright">
      &copy; {new Date().getFullYear()} Home Assist Repairs and Servicing Ltd
    </span>
    <ul className="legal-pages">
      <li>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </li>
      <li>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </li>
    </ul>
    <Accreditations />
    <span>
      Home Assist Repairs and Servicing Ltd registered in England and Wales,
      Company No. 09735664, VAT No. GB322900142. Registered office 41 Osborne
      Road, Jesmond, Newcastle upon Tyne, NE2 2AH. Home Assist Repairs and
      Servicing Trademarks and brands are the property of their respective
      owners. Home Assist Repairs and Servicing Ltd is acting as a licensed
      lender, authorised and regulated by the Financial Conduct Authority, FRN
      774264. All finance is subject to status and income. Applicants must be 18
      or over, terms and conditions apply, guarantees and indemnities may be
      required.
    </span>
  </div>
)
